<template>
  <div class="col-md-10 mx-auto">
    <div class="card shadow-lg rounded">
      <div class="card-body">
        <div class="card-title text-center">
          <h1>Opciones de configuración</h1>
        </div>
        <br>
        <form v-on:submit.prevent="saveItem">
          <div class="form-row px-5">
            <div class="form-group">
              <input type="checkbox" class="form-check-input" id="autorelacion" v-model="item.autorelacion">
              <label class="form-check-label" for="autorelacion">Autorelacionar documentos por número de identificación</label>
            </div>
          </div>
          <div class="form-row px-5">
            <div class="form-group">
              <input type="checkbox" class="form-check-input" id="set_notificacion_email" v-model="item.set_notificacion_email">
              <label class="form-check-label" for="set_notificacion_email">Enviar notificaciones al correo electrónico de los usuarios para el seguimiento de los documentos</label>
            </div>
          </div>
          <div class="form-row px-5">
            <div class="form-group">
              <input type="checkbox" class="form-check-input" id="set_ocultar_fecha_adjuntos" v-model="item.set_ocultar_fecha_adjuntos">
              <label class="form-check-label" for="set_ocultar_fecha_adjuntos">Ocultar fecha de archivos adjuntos en Documentos</label>
            </div>
          </div>
          <div class="text-center">
            <p class="msg">{{ message }}</p>
          </div>
          <div class="row text-center">
            <div class="col-md-12">
              <button type="submit" class="btn btn-primary">Guardar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  //import toastr from 'toastr';

  export default {
    data(){
      return{
        add: true,
        item: {},
        message: '',
        ready: false
      }
    },
    computed: {
      company() {
        return this.$store.state.company
      },
      user() {
        return this.$store.state.user
      }
    },
    created: function() {
      this.getItem();
    },
    methods: {
      saveItem(){      
        if (this.add){
          this.item.cmp_id = String(this.$store.state.company);
          let uri = '/settings/add';
          this.axios.post(uri, this.item)
          .then(response => {
            this.add = false;
            this.message = 'Información guardada.';
          })
          .catch(err => {
            this.message = 'Error al guardar la información.';
          });
        } else {
          let uri = '/settings/update/' + String(this.$store.state.company);
          this.axios.post(uri, this.item)
          .then((response) => {
            this.message = 'Información guardada.';
          })
          .catch((err) => {
            this.message = 'Error al guardar la información.';
          });
        }
      },
      getItem(){
        let uri = '/settings/edit/' + String(this.$store.state.company);
        this.axios.get(uri)
        .then((response) => {
          if (response.data){
            this.add = false;
            this.item = response.data;
          } else {
            this.add = true;
          }
        });
      }
    }
  }
  </script>

  <style>
    .msg {
      color: #ff0000;
      background-color: white;
    }
  </style>
